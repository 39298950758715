@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-black;
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer components {
  .text-glow {
    text-shadow: 0 0 20px rgba(66, 220, 219, 0.3);
  }
  
  .neon-card {
    @apply bg-black/40 backdrop-blur-xl rounded-xl border border-white/10 shadow-[0_0_15px_rgba(66,220,219,0.1)];
    transition: all 0.3s ease;
  }
  
  .neon-card:hover {
    @apply border-[#42DCDB]/30;
    box-shadow: 0 0 30px rgba(66, 220, 219, 0.2);
  }

  .parallax-layer {
    will-change: transform;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-black;
}

::-webkit-scrollbar-thumb {
  @apply bg-[#42DCDB]/20 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-[#42DCDB]/40;
}

/* Add this to your existing styles */
#root {
  isolation: isolate;
  position: relative;
  z-index: 1;
}

.mobile-menu-portal {
  position: relative;
  z-index: 999999;
  isolation: isolate;
} 